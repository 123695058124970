import { type RefObject, useState, useEffect } from 'react'
import { useEventListener, useIntersectionObserver, useWindowSize } from 'usehooks-ts'
import useDebouncedCallback from '@/utils/useDebouncedCallback'
import useMutationObserver from '@/utils/useMutationObserver'
import { cx } from './strings'

type StickyHelperProps = {
  /**
   * Whether to ignore the sticky CTA when calculating the top value.
   */
  ignoreStickyCta?: boolean
  /**
   * The ref of the container element that the sticky element is inside.
   * This is used to determine if the container is larger than the window.
   */
  containerRef?: RefObject<HTMLElement>
  /**
   * The anchor of the sticky tracker.
   * This is used to determine the position of the sticky tracker.
   */
  trackerAnchor?: 'left' | 'right'
}

export default function useStickyHelper(
  { ignoreStickyCta = false, containerRef, trackerAnchor = 'left' }: StickyHelperProps = {
    ignoreStickyCta: false,
    trackerAnchor: 'left',
  }
) {
  const [stickyData, setStickyData] = useState<{
    top: number
    headerHeight: number
    isFloating: boolean
  }>({
    top: 0,
    headerHeight: 0,
    isFloating: false,
  })
  const { height: windowHeight } = useWindowSize()
  const { ref: topTrackerRef, isIntersecting: isTopIntersecting } = useIntersectionObserver({
    threshold: 0,
  })
  const { ref: bottomTrackerRef, isIntersecting: isBottomIntersecting } = useIntersectionObserver({
    threshold: 0,
  })
  const isStuck = isTopIntersecting || isBottomIntersecting

  const calcStickyData = useDebouncedCallback(() => {
    if (typeof document === 'undefined') return
    const header = document.getElementsByTagName('header')[0]
    const headerRect = header?.getBoundingClientRect() || { height: 0, bottom: 0 }
    const stickyCta = document.getElementById('sticky-cta')
    if (!ignoreStickyCta && stickyCta && getComputedStyle(stickyCta).position === 'sticky') {
      const parseValue = (value: string) => parseFloat(value.replace('px', ''))
      const style = getComputedStyle(stickyCta)
      const top = parseValue(style.top) + parseValue(style.height) + 14
      setStickyData({
        top: !Number.isNaN(top) ? top : 0,
        isFloating: true,
        headerHeight: headerRect.height,
      })
    } else if (header) {
      setStickyData({ top: headerRect.bottom, isFloating: false, headerHeight: headerRect.height })
    } else {
      setStickyData({ top: 0, isFloating: false, headerHeight: headerRect.height })
    }
  }, 50)
  useEventListener('resize', calcStickyData)
  useMutationObserver('header', calcStickyData)
  useMutationObserver('#sticky-cta', calcStickyData)
  useEffect(() => {
    calcStickyData()
  }, [isStuck])

  const isContainerLargerThanWindow = containerRef?.current?.getBoundingClientRect().height ?? 0 > windowHeight

  return {
    /**
     * The sticky tracker element that will be used to track the sticky state.
     */
    stickyTracker: (
      <>
        <div
          ref={topTrackerRef}
          className={cx(`absolute`, trackerAnchor === 'left' && `left-0`, trackerAnchor === 'right' && `right-0`)}
          style={{ top: `${windowHeight - stickyData.top}px` }}
        />
        {isContainerLargerThanWindow && (
          <div
            ref={bottomTrackerRef}
            className={cx(`absolute`, trackerAnchor === 'left' && `left-0`, trackerAnchor === 'right' && `right-0`)}
            style={{ bottom: `${stickyData.headerHeight}px` }}
          />
        )}
      </>
    ),
    /**
     * Whether sticky tracker is stuck
     */
    isStuck,
    /**
     * The recommended top value for the sticky element.
     * Takes into account the height of the header and sticky CTA (if present and active)
     */
    top: stickyData.top,
    /**
     * Whether there are other elements (like sticky CTA) that are floating above the sticky element.
     */
    isFloating: stickyData.isFloating,
  }
}
