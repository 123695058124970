import { useEffect, useMemo, useRef, useState } from 'react'
import { motion, useMotionValue, animate, useTransform } from 'framer-motion'

import RichText from '@/components/ui/rich-text'
import ImageContentful from '@/components/ui/image-contentful'

import useStickyHelper from '@/utils/useStickyHelper'
import { getOs } from '@/utils/user-info'

import { TypeComponentTable, TypeTableCell, TypeTableRow } from '@/types/ctf'
import { Table } from '@contentful/rich-text-types'
import { cx } from '@/utils/strings'

type TableEntry = TypeComponentTable<'WITHOUT_UNRESOLVABLE_LINKS'>
type TableCellEntry = TypeTableCell<'WITHOUT_UNRESOLVABLE_LINKS'>
type TableRowEntry = TypeTableRow<'WITHOUT_UNRESOLVABLE_LINKS'>

type TableProps = TableEntry['fields']

export default function Table({
  title,
  subtext,
  headers,
  rows,
  footer,
  stickySections = [],
  tableStyle = 'Default',
}: TableProps) {
  const isHeaderSticky = !!stickySections?.includes('Header')
  const isFirstColumnSticky = !!stickySections?.includes('First Column')

  const styles = useMemo(() => {
    const defaults = {
      columnColors: [] as string[],
      headerStyle: [] as string[],
      bodyStyle: [] as string[],
      firstColumnStyle: [] as string[],
      lastColumnStyle: [] as string[],
      footerStyle: [] as string[],
      rootStyle: [] as string[],
      overflowIndicatorStyle: [] as string[],
    }
    switch (tableStyle) {
      case 'Levels':
        return {
          ...defaults,
          columnColors: [`bg-gray-100`, `bg-white`, `bg-gray-100`, `bg-[#EBF0F8]`, `bg-[#DBE5F6]`, `bg-[#AAC3EF]`],
          bodyStyle: [`border-b border-primaryBlue`],
          footerStyle: [`[&_td]:border-t [&_td]:border-b-0 [&_td]:border-primaryBlue`],
        }
      case 'Default':
      default:
        return {
          ...defaults,
          columnColors: [`bg-white`],
          headerStyle: [
            isHeaderSticky &&
              `bg-[#DBE5F6] border-t border-b first-of-type:border-l first-of-type:rounded-l-lg last-of-type:border-r last-of-type:rounded-r-lg border-[#AAC3EF]`,
            !isHeaderSticky && `bg-white`,
            isFirstColumnSticky &&
              !isHeaderSticky &&
              `first-of-type:bg-[#DBE5F6] first-of-type:border first-of-type:border-b-0 first-of-type:rounded-t-lg first-of-type:border-[#AAC3EF]`,
            isFirstColumnSticky && `!rounded-bl-none`,
          ],
          bodyStyle: [`border-b border-gray-200`],
          firstColumnStyle: [
            isFirstColumnSticky && !isHeaderSticky && `bg-[#DBE5F6] border border-b-0 border-[#AAC3EF]`,
            isFirstColumnSticky && isHeaderSticky && `bg-gray-100 border-gray-200 border-l border-b`,
            !isFirstColumnSticky && `bg-white`,
            isFirstColumnSticky && !footer && `border-r`,
          ],
          lastColumnStyle: [`bg-white`],
          footerStyle: [
            `[&_td]:border-t [&_td]:border-t-[#AAC3EF] [&_td]:border-b-0`,
            isFirstColumnSticky &&
              `[&_td:first-of-type]:rounded-b-lg [&_td:first-of-type]:border-b [&_td:first-of-type]:border-b-[#DBE5F6]`,
          ],
          rootStyle: [isFirstColumnSticky && !footer && `[tbody tr:last-of-type td:first-of-type]:rounded-b-lg`],
          overflowIndicatorStyle: [`bg-gradient-to-b from-[#AAC3EF] to-transparent`],
        }
    }
  }, [tableStyle, footer, isHeaderSticky, isFirstColumnSticky])

  const containerRef = useRef<HTMLDivElement>(null)
  const { stickyTracker, top, isStuck } = useStickyHelper({ containerRef, trackerAnchor: 'right' })

  const isMobile = useMemo(() => {
    const os = getOs()
    return os === 'iOS' || os === 'Android' || os === 'Unknown'
  }, [])
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const tHeadRef = useRef<HTMLTableSectionElement>(null)
  const scrollX = useMotionValue(0)
  const [dragConstraints, setDragConstraints] = useState<{ left: number; width: number | string } | null>(null)
  const [hasOverflow, setHasOverflow] = useState(isMobile)
  const padding = useRef(0)

  const stickyFirstColumnX = useTransform(scrollX, (value) => {
    // Don't stick until we've scrolled past the padding
    if (value > -padding.current) return undefined
    // If we've scrolled past the padding, stick the first column
    if (value < 0) return Math.abs(value + padding.current)
    // For scrolling back and mimicking the elastic effect
    if (value > 0) return value / 100
    return 0
  })

  useEffect(() => {
    const onResize = () => {
      if (containerRef.current) {
        const { paddingLeft } = window.getComputedStyle(containerRef.current)
        padding.current = parseInt(paddingLeft, 10)
      }
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollContainerRef.current
        const isOverflowing = scrollWidth > clientWidth
        setHasOverflow(isOverflowing)
        if (window.getSelection) {
          // Clear highlighted text to prevent it getting stuck in drag
          window.getSelection()?.removeAllRanges()
        }
        scrollX.set(0)
        if (!isOverflowing) {
          setDragConstraints(null)
        } else {
          setDragConstraints({
            left: clientWidth - scrollWidth,
            width: (tableRef.current?.getBoundingClientRect().width ?? 0) + padding.current || '100%',
          })
        }
      }
    }
    if (isMobile) {
      // Use orientation change because resize event randomly fires on mobile
      window.screen.orientation.addEventListener('change', onResize)
      onResize()
      return () => {
        window.screen.orientation.removeEventListener('change', onResize)
      }
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const renderBodyCell = (
    cell: Partial<TableCellEntry['fields']>,
    cellIndex: number,
    row: TableRowEntry['fields'],
    rowIndex: number
  ) => {
    const isFirstColumn = cellIndex === 0
    const isLastColumn = cellIndex === (row?.cells?.length ?? 0) - 1 + (row.title ? 1 : 0)
    return (
      <motion.td
        key={cell.internalName}
        className={cx(
          `relative p-6 align-top [p]:leading-tight [*:last-of-type]:mb-0!`,
          isFirstColumn && isFirstColumnSticky && `z-[1]`,
          ...(rowIndex < (rows?.length ?? 0) - 1 ? styles.bodyStyle : []),
          styles.columnColors[cellIndex % styles.columnColors.length],
          ...(isFirstColumn ? styles.firstColumnStyle : []),
          ...(isLastColumn ? styles.lastColumnStyle : [])
        )}
        style={
          isFirstColumn && isFirstColumnSticky
            ? {
                x: stickyFirstColumnX,
              }
            : undefined
        }
      >
        <div
          className={cx(isFirstColumn && isFirstColumnSticky && `sticky`)}
          style={
            isFirstColumn && isFirstColumnSticky
              ? { top: (isHeaderSticky ? (tHeadRef.current?.getBoundingClientRect().height ?? 0) : 0) + top + 16 }
              : undefined
          }
        >
          <RichText body={cell.content as any} />
        </div>
      </motion.td>
    )
  }

  const getScrollEnd = () => dragConstraints?.left ?? 0

  const handleScroll = (e: React.WheelEvent) => {
    const latest = scrollX.get()
    const newValue = Math.min(0, Math.max(latest - e.deltaX, getScrollEnd()))
    scrollX.set(newValue)
  }
  const handleKeyDown = (e: React.KeyboardEvent) => {
    const latest = scrollX.get()
    const distance = 150
    if (e.key === 'ArrowLeft') {
      animate(scrollX, Math.min(0, latest + distance))
    }
    if (e.key === 'ArrowRight') {
      animate(scrollX, Math.max(latest - distance, getScrollEnd()))
    }
  }

  return (
    <section className="relative max-w-8xl mx-auto px-5 lg:px-9 overflow-clip" ref={containerRef}>
      {title && <h3 className={cx('text-center', subtext ? `mb-4` : `mb-10`)}>{title}</h3>}
      {subtext && <p className="text-center mb-10">{subtext}</p>}
      {/* Mask for hiding grid above sticky header */}
      {isHeaderSticky && (
        <motion.div
          className={cx(
            'sticky left-0 z-[11] w-full pointer-events-none transition-opacity duration-100',
            ...styles.overflowIndicatorStyle
          )}
          style={{
            opacity: isStuck ? 1 : 0,
            top,
            x: scrollX,
            width: dragConstraints?.width ?? '100%',
          }}
        >
          <div
            className="absolute top-0 left-0 z-10 w-full bg-white"
            style={{
              height: top,
              top: -top,
            }}
          />
        </motion.div>
      )}

      <motion.div className="relative" ref={scrollContainerRef}>
        {stickyTracker}
        <motion.table
          ref={tableRef}
          className={cx(
            'relative border-separate border-spacing-0 [&_h2,&_h3,&_h4,&_h5,&_h6]:font-normal [&_h5]:text-xl [&_h6]:text-base min-w-full',
            ...styles.rootStyle
          )}
          drag={hasOverflow || isMobile ? 'x' : false}
          draggable={hasOverflow || isMobile}
          dragConstraints={{ left: dragConstraints?.left ?? 0, right: 0 }}
          style={{ x: scrollX }}
          whileHover={hasOverflow && !isMobile ? { cursor: 'grab' } : undefined}
          whileDrag={hasOverflow && !isMobile ? { cursor: 'grabbing' } : undefined}
          tabIndex={!isMobile ? 0 : undefined}
          onWheel={handleScroll}
          onKeyDown={handleKeyDown}
        >
          <thead ref={tHeadRef}>
            <tr className={cx(isHeaderSticky && `sticky z-[2]`)} style={{ top }}>
              {headers?.map((header, index) => {
                const isFirstColumn = index === 0
                const isLastColumn = index === headers.length - 1
                return (
                  <motion.th
                    key={header?.sys.id}
                    scope="col"
                    className={cx(
                      `p-6 text-left [&_*:last-of-type]:!mb-0`,
                      ...styles.bodyStyle,
                      styles.columnColors[index % styles.columnColors.length],
                      isFirstColumn && isFirstColumnSticky && `sticky left-0 z-[1]`,
                      ...(isFirstColumn ? styles.firstColumnStyle : []),
                      ...(isLastColumn ? styles.lastColumnStyle : []),
                      ...styles.headerStyle
                    )}
                    style={
                      isFirstColumn && isFirstColumnSticky
                        ? {
                            x: stickyFirstColumnX,
                          }
                        : undefined
                    }
                  >
                    <div className="table-header-cell-container flex gap-4">
                      {header?.fields?.icon && (
                        <div className="table-header-icon w-14 h-14 rounded-md overflow-hidden [&_svg]:fill-none [&_svg]:w-full [&_svg]:h-full">
                          <ImageContentful
                            {...header.fields.icon.fields}
                            title={header.fields.icon.fields.title || ''}
                          />
                        </div>
                      )}
                      <div className="flex-1">
                        <RichText body={header?.fields.content} />
                      </div>
                    </div>
                  </motion.th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, rowIndex) => (
              <tr key={row?.sys.id}>
                {row?.fields.title &&
                  renderBodyCell(
                    { internalName: 'rowTitle', content: row.fields.title, icon: undefined },
                    0,
                    row.fields,
                    rowIndex
                  )}
                {row?.fields.cells?.map(
                  (cell, cellIndex) =>
                    cell && renderBodyCell(cell.fields, cellIndex + (row.fields.title ? 1 : 0), row.fields, rowIndex)
                )}
              </tr>
            ))}
          </tbody>
          {footer && (
            <tfoot>
              <tr className={cx(...styles.footerStyle)}>
                {footer.fields.title &&
                  renderBodyCell(
                    { internalName: 'footerTitle', content: footer.fields.title, icon: undefined },
                    0,
                    footer.fields,
                    0
                  )}
                {footer.fields.cells?.map((cell, index) => {
                  const isFirstColumn = index === 0 && !footer.fields.title
                  return (
                    <td
                      key={cell?.sys.id}
                      className={cx(
                        `relative p-6 align-top border-t border-primaryBlue`,
                        styles.columnColors[(index + (footer.fields.title ? 1 : 0)) % styles.columnColors.length],
                        isFirstColumn && isFirstColumnSticky && `z-[1]`
                      )}
                    >
                      <div
                        className={cx(isFirstColumn && isFirstColumnSticky && `sticky`)}
                        style={
                          isFirstColumn && isFirstColumnSticky
                            ? { top: (tHeadRef.current?.getBoundingClientRect().height ?? 0) + top + 16 }
                            : undefined
                        }
                      >
                        <RichText body={cell?.fields.content} />
                      </div>
                    </td>
                  )
                })}
              </tr>
            </tfoot>
          )}
        </motion.table>
      </motion.div>
    </section>
  )
}
