export function getBrowserName() {
  let browserName = 'Unknown'
  if (typeof navigator === 'undefined') return browserName
  const { userAgent } = navigator

  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox'
  }
  if (userAgent.includes('Safari')) {
    browserName = 'Safari'
  }
  if (userAgent.includes('Chrome')) {
    browserName = 'Chrome'
  }
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera'
  }
  if ((navigator as any).brave) {
    browserName = 'Brave'
  }
  if (userAgent.includes('Ddg')) {
    browserName = 'DuckDuckGo'
  }
  if (userAgent.includes('Vivaldi')) {
    browserName = 'Vivaldi'
  }
  if (userAgent.includes('MSIE')) {
    browserName = 'Internet Explorer'
  }
  if (userAgent.includes('Edg')) {
    browserName = 'Edge'
  }
  return browserName
}

export function getOs() {
  let osName = 'Unknown'
  if (typeof navigator === 'undefined') return osName
  const platform = navigator.userAgent
  if (platform.includes('Win')) {
    osName = 'Windows'
  } else if (platform.includes('Mac')) {
    osName = 'MacOS'
  } else if (platform.includes('Linux')) {
    osName = 'Linux'
  } else if (platform.includes('iPhone') || platform.includes('iPad') || platform.includes('iPod')) {
    osName = 'iOS'
  } else if (platform.includes('Android')) {
    osName = 'Android'
  }
  return osName
}
